export function initAusPageAjax(): void {
  document.querySelectorAll('[data-auspage-ajax-uid]').forEach((ausPageDiv: HTMLElement) => {
    const uid = parseInt(ausPageDiv?.dataset.auspageAjaxUid ?? '', 10);
    if (!uid) {
      return;
    }
    addLinkListener(ausPageDiv, uid);
  });
}

function addLinkListener(ausPageDiv: HTMLElement, uid: number): void {
  ausPageDiv.querySelectorAll('a[data-auspage-ajax]').forEach((link: HTMLAnchorElement) => {
    link.addEventListener('click', async (event) => {
      event.preventDefault();
      smartScrollIntoView(ausPageDiv);

      const animation = ausPageDiv.animate(
        [
          {opacity: 0.2},
          {opacity: 0.5},
        ],
        {
          duration: 900,
          iterations: Infinity,
          easing: 'cubic-bezier(0.27, -0.01, 0.51, 0.97)',
          direction: 'alternate' as 'alternate',
        },
      );

      let response;
      try {
        response = await fetch(link.href);
      } catch (e) {
        window.location.href = link.href;
        return;
      }
      const html = await response.text();
      const detachedElement = document.createElement('div');
      detachedElement.innerHTML = html;
      const inner = detachedElement.querySelector('[data-auspage-ajax-uid="' + uid + '"]');
      if (!inner) {
        window.location.href = link.href;
        return;
      }
      ausPageDiv.innerHTML = inner.innerHTML;
      window.history.pushState({}, '', link.href);
      animation.cancel();
      addLinkListener(ausPageDiv, uid);
    });
  });
}

function smartScrollIntoView(div: HTMLElement): void {
  const top = div.getBoundingClientRect().top;
  if (top > 0 && top < window.innerHeight) {
    return;
  }
  div.scrollIntoView();
  window.scrollTo({top: Math.max(0, div.offsetTop - 60), left: div.offsetLeft});
}
