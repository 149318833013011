export class FileUploadController {

  private uploadInput: HTMLInputElement | null = document.querySelector('.js-application-fileupload');
  private dropZone: HTMLElement | null;
  private filesArray: Array<File> = [];
  private totalFileSize = 0;

  constructor() {
    this.dropZone = document.querySelector('.js-dropzone');
    if (this.dropZone && this.uploadInput) {
      this.dropZone.addEventListener('dragover', (evt) => {
        this.manageDragOver(evt);
      });
      this.dropZone.addEventListener('drop', (evt) => {
        this.manageFiles(evt);
      });
      this.uploadInput.addEventListener('change', (evt) => {
        this.manageFiles(evt);
      });
    }
  }

  /**
   * @params {File[]} files Array of files to add to the FileList
   * @return {FileList}
   */
  getFileListItems(files) {
    let b = new ClipboardEvent('').clipboardData || new DataTransfer();
    for (let i = 0, len = files.length; i < len; i++) b.items.add(files[i]);
    return b.files;
  }

  showUploadedFiles() {
    if (!this.uploadInput) {
      return;
    }
    let output: Array<string> = [];
    const uploadedFileList = document.getElementById('uploaded-filelist');
    if (!uploadedFileList) {
      return;
    }
    uploadedFileList.innerHTML = '';
    this.uploadInput.files = this.getFileListItems(this.filesArray);
    let uploadedFilesSection = document.querySelector('.js-files');
    if (this.filesArray.length > 0) {
      uploadedFilesSection?.classList.remove('hide');
    } else {
      uploadedFilesSection?.classList.add('hide');
    }
    this.filesArray.forEach((val, key) => {
      if (!this.uploadInput) {
        return;
      }
      output.push('<li><span class="file file--mime-application-pdf file--application-pdf">', escape(val.name), '</span>&nbsp;<span class="js-removeFile delete-file-career-form form-msg--error" data-key="' + key + '" > ' +  this.uploadInput.parentElement?.dataset.removetxt + '</span></li>');
    });
    uploadedFileList.innerHTML += '<ul>' + output.join('') + '</ul>';
    this.initRemoveListener();
  }

  manageDragOver(evt) {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = 'copy';
  }

  manageFiles(evt) {
    let files: Array<File> = [];
    evt.preventDefault();
    if (evt.dataTransfer) {
      files = evt.dataTransfer.files;
    } else if (evt.target) {
      files = evt.target.files;
    }
    if (files.length > 5) {
      if (!this.dropZone) {
        return;
      }
      alert(this.dropZone.dataset.numerror);
    } else {
      let key = 0;
      if (this.filesArray) {
        key = this.filesArray.length;
      }
      for (let i = 0, f; f = files[i]; i++) {
        if (!this.dropZone) {
          return;
        }
        let copyTotalFileSize = this.totalFileSize;
        if (f.type !== 'application/pdf') {
          alert(this.dropZone.dataset.filetype);
        } else if ((copyTotalFileSize = this.totalFileSize + f.size) > 20000000) {
          alert(this.dropZone.dataset.maxfilesize);
          return;
        } else if (this.filesArray.length >= 5) {
          alert(this.dropZone.dataset.numerror);
        } else {
          this.totalFileSize = this.totalFileSize + f.size;
          this.filesArray[key] = f;
          key++;
        }
      }
    }
    this.showUploadedFiles();
  }

  initRemoveListener() {
    const removeBtn = document.querySelectorAll('.js-removeFile');
    if (removeBtn) {
      removeBtn.forEach(elem => {
        elem.addEventListener('click', e => {
          const target: HTMLElement | null = e.currentTarget as HTMLElement || null;
          if (!target) {
            return;
          }
          const key = target.dataset.key || '';
          const id: number = parseInt(key, 10);
          // Update size of total uploads
          this.totalFileSize = this.totalFileSize - this.filesArray[id].size;
          // Remove file
          this.filesArray.splice(id, 1);
          this.showUploadedFiles();
        });
      });
    }
  }
}
