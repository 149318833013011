/**
 * Copy and TS-Version of core default_frontend.js
 */
export default class MailtoDecryptController {

  constructor() {
    this.initController();
  }

  initController() {

    // Get all mailto elements that are rendered via fluid link.email.
    const mailtoElements = document.querySelectorAll('a[data-mailto-token][data-mailto-vector]');
    mailtoElements.forEach(element => {
      element.addEventListener('click', (evt) => {
        evt.preventDefault();
        let dataset = (evt.currentTarget as HTMLElement).dataset;

        // Decrypt strings and reveal link.
        if (typeof dataset.mailtoToken !== 'undefined' && typeof dataset.mailtoVector !== 'undefined') {
          let value = dataset.mailtoToken;
          let offset = parseInt(dataset.mailtoVector, 10) * -1;
          document.location.href = this.decryptString(value, offset);
        }
      });
    });
  }

  decryptCharcode(charCode, start, end, offset) {
    charCode = charCode + offset;
    if (offset > 0 && charCode > end) {
      charCode = start + (charCode - end - 1);
    } else if (offset < 0 && charCode < start) {
      charCode = end - (start - charCode - 1);
    }
    return String.fromCharCode(charCode);
  }

  decryptString(value, offset) {
    let result = '';
    for (let i = 0; i < value.length; i++) {
      let charCode = value.charCodeAt(i);
      if (charCode >= 0x2B && charCode <= 0x3A) {
        result += this.decryptCharcode(charCode, 0x2B, 0x3A, offset);	/* 0-9 . , - + / : */
      } else if (charCode >= 0x40 && charCode <= 0x5A) {
        result += this.decryptCharcode(charCode, 0x40, 0x5A, offset);	/* A-Z @ */
      } else if (charCode >= 0x61 && charCode <= 0x7A) {
        result += this.decryptCharcode(charCode, 0x61, 0x7A, offset);	/* a-z */
      } else {
        result += value.charAt(i);
      }
    }
    return result;
  }
}
