export default class MobileNavigationController {

  mobileNavButton = document.querySelector('.js-mobileNavButton');
  mobileMenu = document.querySelector('.js-mobileMenu');

  constructor() {

    if (!this.mobileNavButton || !this.mobileMenu) {
      return;
    }

    this.mobileNavButton.addEventListener('click', () => {
      if (this.isMobileMenuOpen()) {
        this.closeMobileMenu();
      } else {
        this.openMobileMenu();
      }
    });

    document.addEventListener('keyup', (event) => {
      if (event.key === 'Escape') {
        this.closeMobileMenu();
      }
    });

    document.querySelector('.js-closeMobileMenu')?.addEventListener('click', () => {
      this.closeMobileMenu();
    });

    const openSubmenuButtons = document.querySelectorAll('.js-openMobileSubmenu');
    openSubmenuButtons.forEach((openSubmenuButton) => {
      openSubmenuButton.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.openSubmenu(event.currentTarget);
      });
    });

    const submenuBackButtons = document.querySelectorAll('.js-mobileSubmenuBack');
    submenuBackButtons.forEach((submenuBackButton) => {
      submenuBackButton.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.closeSubmenu(event.currentTarget);
      });
    });

  }

  isMobileMenuOpen(): boolean {
    if (!this.mobileMenu) {
      return false;
    }
    return this.mobileMenu.classList.contains('mobileMenu--open');
  }

  closeMobileMenu() {
    if (!this.mobileMenu) {
      return;
    }
    this.mobileMenu.classList.remove('mobileMenu--open');
    document.querySelector('html')?.classList.remove('no-scroll');
  }

  openMobileMenu() {
    if (!this.mobileMenu) {
      return;
    }
    this.mobileMenu.classList.add('mobileMenu--open');
    document.querySelector('html')?.classList.add('no-scroll');
  }

  openSubmenu(element) {
    const subElementList = element.parentElement.parentElement.querySelector('.mobileMenu--submenu');
    if (!subElementList) {
      return;
    }
    subElementList.classList.add('mobileMenu--open');
  }

  closeSubmenu(element) {
    element.parentElement?.classList.remove('mobileMenu--open');
  }

}
