import Swiper, {Lazy, Navigation, Pagination} from 'swiper';

export function initHistorySlider(scope: Document | HTMLElement = document) {
  const sliderElement: HTMLElement|null = document.querySelector('.js-historySlider');

  if (sliderElement) {
    const swiper = new Swiper(sliderElement, {
      modules: [Navigation, Pagination, Lazy],
      navigation: {
        nextEl: '.js-slider-next',
        prevEl: '.js-slider-prev',
      },
      pagination: {
        el: '.js-slider-pagination',
        clickable: true,
      },
      preloadImages: false,
      lazy : {
        enabled: true,
        loadPrevNext: true,
        loadPrevNextAmount: 1,
      },
    });
  }
}
