import SimpleLightbox from 'simplelightbox';

export function initLightbox(scope: Document | HTMLElement = document): void {
  const lightbox = new SimpleLightbox('.js-lightbox', {
    overlay: true,
    overlayOpacity: 0.7,
    close: true,
    scrollZoom: false,
    navText: ['<span class="material-icons-sharp">chevron_left</span>',
      '<span class="material-icons-sharp">chevron_right</span>'],
  });
}
