export default class FormController {
  private contactFormElements: NodeListOf<HTMLElement> | null;

  constructor() {
    this.contactFormElements = document.querySelectorAll('.js-form');
    this.contactFormElements?.forEach((formElement) => {
      const form: HTMLFormElement | null = document.querySelector('.js-form');
      const submitButtonElement: HTMLInputElement | null = formElement.querySelector('input[type="submit"]');

      submitButtonElement?.addEventListener('click', () => {
        if (form) {
          const requiredElems: NodeListOf<HTMLInputElement> = form.querySelectorAll('input[required], textarea[required]');
          for (let i = 0; i < requiredElems.length; i++) {
            requiredElems[i].classList.add('showValidity');
          }
        }
      });
    });
  }
}
