import move from "move-js";

export function scrollable(wrapperElement) {

  const scrollBarWidths = 40;
  const wrapper = wrapperElement.getElementsByClassName("wrapper-nav")[0];
  const navLink = wrapperElement.getElementsByClassName("nav-item nav-link");
  const lastNavLink = navLink[navLink.length - 1];

  const scrollerRight = wrapperElement.getElementsByClassName("scroller-right")[0];
  const scrollerLeft = wrapperElement.getElementsByClassName("scroller-left")[0];

  const list = wrapperElement.querySelectorAll(".list");

  let btnTriggered = false;

  let widthOfList = function () {
    let itemsWidth = 0;

    const listLinks = wrapperElement.querySelectorAll(".list a");
    listLinks.forEach((el) => {
      let itemWidth = getOuterWidth(el);
      itemsWidth += itemWidth;
    });

    return itemsWidth;
  };

  let widthOfHidden = function (width) {
    const wrapperh = wrapperElement.getElementsByClassName("wrapper-nav")[0];

    width = (!width) ? 0 : width;

    let outerWidth = getOuterWidth(wrapperh) - width;

    let wrapperWidth = parseFloat((0 - outerWidth).toFixed(3));

    let holeWrapper = (outerWidth - widthOfList() - getLeftPosi()) - scrollBarWidths;

    let rightPosition = document.body.clientWidth - (getOuterLeft(lastNavLink) + getOuterWidth(lastNavLink)) - width;

    if (wrapperWidth > holeWrapper) {
      return (rightPosition > wrapperWidth ? rightPosition : wrapperWidth);
    } else {
      return (rightPosition > wrapperWidth ? rightPosition : wrapperWidth);
    }
  };

  let getLeftPosi = function () {
    let wrapperWidth = 0 - getOuterWidth(wrapper);
    let leftPosition = getOuterLeft(list[0]);

    if (wrapperWidth > leftPosition) {
      return wrapperWidth;
    } else {
      return leftPosition;
    }
  };

  let reAdjust = function () {
    let rightPosition = document.body.clientWidth - (getOuterLeft(lastNavLink) + getOuterWidth(lastNavLink));

    if (getOuterWidth(wrapper) < widthOfList() && (rightPosition < 0)) {
      scrollerRight.style.cssText = 'display: flex';
    } else {
      scrollerRight.style.display = 'none';
    }

    if (getLeftPosi() < 0) {
      scrollerLeft.style.cssText = 'display: flex';
    } else {
      scrollerLeft.style.display = 'none';
    }

    btnTriggered = false;
  };

  window.addEventListener('resize', function (event) {
    reAdjust();
  }, true);

  scrollerRight.addEventListener("click", function () {
    if (btnTriggered) return;

    btnTriggered = true;

    fade(scrollerLeft);
    unfade(scrollerRight);

    let widthRight = getOuterWidth(scrollerRight);

    move(wrapperElement.querySelectorAll(".list")[0]).add("left", +widthOfHidden(widthRight) - 12, 200).end().then(x => {
      reAdjust();
    });
  });

  scrollerLeft.addEventListener("click", function () {
    if (btnTriggered) return;

    btnTriggered = true;

    fade(scrollerRight);
    unfade(scrollerLeft);

    let widthLeft = getOuterWidth(scrollerLeft);

    move(wrapperElement.querySelectorAll(".list")[0]).add("left", -getLeftPosi() + widthLeft + 11.375, 200).end().then(() => {
      reAdjust();
    });
  });

  let getOuterLeft = function (elem) {
    return elem.getBoundingClientRect().left;
  };

  let getOuterWidth = function (elem) {
    return parseFloat(elem.getBoundingClientRect().width);
  };

  function fade(elem) {
    elem.style.display = "none";
    elem.style.transition = "opacity 0.6s";
    elem.style.opacity = 0;
  }

  function unfade(elem) {
    elem.style.display = "block";
    elem.style.transition = "opacity 0.6s";
    elem.style.opacity = 1;
  }

  reAdjust();


}
