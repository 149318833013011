import { Collapse } from 'bootstrap';

export default class AccordionController {

  constructor() {
    const allAccordionGroups = document.querySelectorAll('.js-accordionGroup');
    allAccordionGroups.forEach(accordionGroup => this.initAccordionGroup(accordionGroup));
    const elements = document.querySelectorAll('.js-accordion');
    elements.forEach((accordion) => {
      new Collapse(accordion);
    });
  }

  initAccordionGroup(accordionGroup) {

    // Get hash from url if exists.
    const hash = window.location.hash;
    accordionGroup.querySelectorAll('.accordion-item').forEach((accItem: HTMLElement) => {

      const parent = accItem.closest('.accordion');
      const parentId = parent!.getAttribute('id');
      const accButton = accItem.querySelector('button.accordion-button');
      const accContent = accItem.querySelector('div.accordion-collapse');
      const hashIndicator: string = accItem.querySelector('.accordion-header')?.getAttribute('data-hash') || '';

      // If the item has the needed classes, proceed.
      if (!(accButton instanceof HTMLElement && accContent instanceof HTMLElement)) {
        return;
      }
      // Click handler to update the hash.
      accButton.addEventListener('click', event => {
        window.location.hash = hashIndicator;
      });

      // Add parent indicator to acc content.
      accContent.setAttribute('data-bs-parent', '#' + parentId);

      // If there is a hash, open the related accordion item.
      if (hash && hashIndicator === hash) {
        accButton.click();
      }
    });
  }

}
