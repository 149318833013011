import SlimSelect from 'slim-select';
import {Option} from 'slim-select/dist/data';

export class SalesContactController {

  private filterSelects: NodeListOf<HTMLSelectElement> = document.querySelectorAll('.js-salescontact-filter');
  private countrySelectWrapper = document.querySelector('.filter__item--countries');
  private radioSelects: NodeListOf<HTMLInputElement> = document.querySelectorAll('.filter__item--divisionRadio');
  private countriesSlimSelect: SlimSelect | null = null;
  private salesContactItems: NodeListOf<HTMLElement> = document.querySelectorAll('.js-salescontact-item');
  private salesContactButtons: NodeListOf<HTMLElement> = document.querySelectorAll('.js-salescontact-button');
  private filter: Record<string, { selectedValue: string, activeValues: string[] }> = {};
  private reset = document.querySelector('.js-filter-title-reset');

  constructor() {
    this.filterSelects?.forEach(filterSelect => {
      this.initSlimSelect(filterSelect, filterSelect.id);
    });
    this.radioSelects.forEach(radioSelect => {
      radioSelect.addEventListener('change', () => {
        this.countriesSlimSelect?.set('all');
        if (radioSelect.checked) {
          this.reset?.classList.remove('hide');
          this.filter.division = {selectedValue: radioSelect.value, activeValues: []};
          this.countrySelectWrapper?.classList.remove('hide');
          const selectedValue = radioSelect.value;
          this.filterSelects?.forEach(filterSelect => {
            this.filter[filterSelect.id] = {selectedValue: filterSelect.value, activeValues: []};
            filterSelect.addEventListener('change', (event) => {
              this.filter[filterSelect.id].selectedValue = filterSelect.value;
              this.updateSalesContactList();
              this.setFilter();
            });
          });
          this.salesContactButtons.forEach(salesContactButton => {
            if (salesContactButton.dataset.division === selectedValue) {
              salesContactButton.classList.remove('hide');
            } else {
              salesContactButton.classList.add('hide');
            }
          });
          this.updateSalesContactList();
          this.setFilter();
        }
      });
    });
    this.setFilter();
    this.reset?.addEventListener('click', () => {
      this.radioSelects.forEach(radioSelect => {
        radioSelect.checked = false;
      });
      this.salesContactItems.forEach(salesContactItem => {
        salesContactItem.classList.add('hide');
      });
      this.reset?.classList.add('hide');
      this.countrySelectWrapper?.classList.add('hide');
      this.salesContactButtons.forEach(salesContactButton => {
        salesContactButton.classList.add('hide');
      });
    });
  }

  private updateSalesContactList() {
    this.salesContactItems.forEach(salesContactItem => {
      salesContactItem.classList.add('hide');
    });
    salesContactItemLoop: for (let i = 0; i < this.salesContactItems.length; i++) {
      for (let filterKey in this.filter) {
        if (this.filter.hasOwnProperty(filterKey)) {
          let filterValue = this.filter[filterKey].selectedValue;
          let salesContactCountries = this.salesContactItems[i].dataset[filterKey];
          let countries = salesContactCountries?.split(',');
          if (
            (!countries?.includes(filterValue)
              && this.filter[filterKey].selectedValue !== 'all')
            || (filterKey === 'division' && filterValue === 'all')) {
            continue salesContactItemLoop;
          }
        }
      }
      this.salesContactItems[i].classList.remove('hide');
    }
  }

  private setFilter() {
    for (let setFilterKey in this.filter) {
      if (this.filter.hasOwnProperty(setFilterKey)) {
        this.filter[setFilterKey].activeValues = [];
        salesContactItemLoop: for (let i = 0; i < this.salesContactItems.length; i++) {
          for (let salesContactItemKey in this.filter) {
            if (salesContactItemKey !== setFilterKey) {
              let selectedValue = this.filter[salesContactItemKey].selectedValue;
              let salesContactCountries = this.salesContactItems[i].dataset[salesContactItemKey];
              let countries = salesContactCountries?.split(',');
              if (
                (!countries?.includes(selectedValue)
                  && this.filter[salesContactItemKey].selectedValue !== 'all')
                || (salesContactItemKey === 'division' && selectedValue === 'all')) {
                continue salesContactItemLoop;
              }
            }
          }
          let itemFilterValue = this.salesContactItems[i].dataset[setFilterKey];
          if (itemFilterValue) {
            if (!this.filter[setFilterKey].activeValues.includes(itemFilterValue)) {
              let filterValueArray = itemFilterValue.split(',');
              filterValueArray.forEach((filterValue: string) => {
                this.filter[setFilterKey].activeValues.push(filterValue);
              });
            }
          }
        }
        this.setSelectByKey(setFilterKey);
      }
    }
  }

  private setSelectByKey(filterKey: string) {
    const activeValues = this.filter[filterKey].activeValues;
    this.countriesSlimSelect?.data.data.forEach((option: Option) => {
      if (option.value) {
        option.disabled = !activeValues.includes(option.value) && !(option.value === 'all');
      }
    });
    this.countriesSlimSelect?.render();
  }

  private initSlimSelect(htmlSelect: HTMLSelectElement, id: string) {
    if (id === 'countries') {
      this.countriesSlimSelect = new SlimSelect({
        select: htmlSelect,
        showSearch: true,
      });
      return;
    }
  }
}
