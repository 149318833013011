import {Tab} from 'bootstrap';

import {scrollable} from '../Lib/bs5-scrollable-tabs/main';

export default class TabNavigationController {

  private readonly hash = window.location.hash;

  constructor() {
    const tabElements = document.querySelectorAll('[data-tabs]');
    setTimeout(() => tabElements.forEach(this.initTabElement), 200);

    const elements = document.querySelectorAll('[data-bs-toggle="tab"]');
    elements.forEach((element) => {
      const tab = new Tab(element);
      if (this.hash && element instanceof HTMLElement && element.dataset.bsTarget === this.hash) {
        tab.show();
      }
      element.addEventListener('click', event => {
        if (element instanceof HTMLElement && element.dataset.bsTarget) {
          window.location.hash = element.dataset.bsTarget;
        }
        event.preventDefault();
        tab.show();
      });
    });

    const allTabGroups = document.querySelectorAll('.js-bsTabs');
    allTabGroups.forEach(tabGroup => this.initTabGroup(tabGroup));
  }

  initTabElement(element: HTMLElement) {
    scrollable(element);
  }

  initTabGroup(tabGroup) {
    if (this.hash) {
      const tabBodys = tabGroup.querySelectorAll('.tab-pane');
      tabBodys.forEach(tabBody => {
        if (this.hash && '#' + tabBody.id === this.hash) {
          tabBody.classList.add('active');
          tabBody.classList.add('show');
        }
      });
    }
    // Activate first Tab, if no hash exists.
    if (!this.hash) {
      const firstTabContent = tabGroup.querySelector('.tab-content div.tab-pane:first-of-type');
      if (firstTabContent instanceof HTMLElement) {
        firstTabContent.classList.add('active');
        firstTabContent.classList.add('show');
      }
    }
  }
}
