import MailtoDecryptController from './MailtoDecryptController';

export default class ContactInfoController {
  private readonly contactInfoFlyoutWrapper = document.querySelectorAll('.js-contactInfo');
  private readonly contactInformationCallbackUrl = window.location.href.split(/[?#]/)[0] + '?type=1653884369';

  constructor() {
    if (this.contactInfoFlyoutWrapper) {
      this.requestContactInformation();
    }

    const contactButtons = document.querySelectorAll('.js-contact-button button');
    if (!contactButtons) {
      return;
    }
    contactButtons.forEach((contactButton) => {
      contactButton.addEventListener('click', () => {
        const contactInfo = contactButton.nextElementSibling;
        contactInfo?.classList.remove('hide');

        const closeButton = contactInfo?.querySelector('.js-contact-close');
        if (!closeButton) {
          return;
        }
        closeButton.addEventListener('click', () => {
          contactInfo?.classList.add('hide');
        });
      });
    });

  }

  /**
   * Request the contact information asynchronously and insert html if successful.
   */
  async requestContactInformation() {
    const response = await fetch(this.contactInformationCallbackUrl);
    if (response.ok && this.contactInfoFlyoutWrapper) {
      const html: string = await response.text();
      // remove contact button if no contact info available
      if (html.length < 10) {
        const contactIcons = document.querySelectorAll('.js-contact-button');
        contactIcons?.forEach((contactIcon) => {
          contactIcon.classList.add('hide');
        });
      }
      for (let i = 0; i < this.contactInfoFlyoutWrapper.length; i++) {
        this.contactInfoFlyoutWrapper[i].insertAdjacentHTML('beforeend', html);
      }
      // After html is inserted, call the frontend controller which then applies link.email decryption.
      const mailtoController = new MailtoDecryptController();
      mailtoController.initController();
    } else {
      return;
    }
  }

}
