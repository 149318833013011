/// <reference types="@types/google.maps" />
import {MarkerClusterer} from '@googlemaps/markerclusterer';

export default class MapController {
  map: google.maps.Map | null = null;
  markerArray: google.maps.Marker[] = [];
  infoboxArray: google.maps.InfoWindow[] = [];
  mapElem: HTMLElement | null = document.querySelector('.js-map');
  markerUrlInactive: string = '';
  markerUrlActive: string = '';

  constructor() {
    if (this.mapElem) {
      this.map = new google.maps.Map(this.mapElem, {
        center: {lat: 51.1642292, lng: 10.4541194},
        zoom: 6,
        disableDefaultUI: false,
        streetViewControl: false,
        styles: [
          {
            'elementType': 'geometry',
            'stylers': [
              {
                'color': '#e5e5e5',
              },
            ],
          },
          {
            'elementType': 'labels.icon',
            'stylers': [
              {
                'visibility': 'off',
              },
            ],
          },
          {
            'elementType': 'labels.text.fill',
            'stylers': [
              {
                'color': '#616161',
              },
            ],
          },
          {
            'elementType': 'labels.text.stroke',
            'stylers': [
              {
                'color': '#e5e5e5',
              },
            ],
          },
          {
            'featureType': 'administrative.land_parcel',
            'elementType': 'labels.text.fill',
            'stylers': [
              {
                'color': '#b9b9b9',
              },
            ],
          },
          {
            'featureType': 'administrative.locality',
            'stylers': [
              {
                'visibility': 'simplified',
              },
            ],
          },
          {
            'featureType': 'poi',
            'elementType': 'geometry',
            'stylers': [
              {
                'color': '#e9e9e9',
              },
            ],
          },
          {
            'featureType': 'poi',
            'elementType': 'labels.text.fill',
            'stylers': [
              {
                'color': '#707070',
              },
            ],
          },
          {
            'featureType': 'poi.park',
            'elementType': 'geometry',
            'stylers': [
              {
                'color': '#d0d0d0',
              },
            ],
          },
          {
            'featureType': 'poi.park',
            'elementType': 'labels.text.fill',
            'stylers': [
              {
                'color': '#999999',
              },
            ],
          },
          {
            'featureType': 'road',
            'elementType': 'geometry',
            'stylers': [
              {
                'color': '#e5e5e5',
              },
            ],
          },
          {
            'featureType': 'road.arterial',
            'elementType': 'labels.text.fill',
            'stylers': [
              {
                'color': '#707070',
              },
            ],
          },
          {
            'featureType': 'road.highway',
            'elementType': 'geometry',
            'stylers': [
              {
                'color': '#cacaca',
              },
            ],
          },
          {
            'featureType': 'road.highway',
            'elementType': 'labels.text.fill',
            'stylers': [
              {
                'color': '#616161',
              },
            ],
          },
          {
            'featureType': 'road.local',
            'elementType': 'labels.text.fill',
            'stylers': [
              {
                'color': '#999999',
              },
            ],
          },
          {
            'featureType': 'transit.line',
            'elementType': 'geometry',
            'stylers': [
              {
                'color': '#d0d0d0',
              },
            ],
          },
          {
            'featureType': 'transit.station',
            'elementType': 'geometry',
            'stylers': [
              {
                'color': '#e9e9e9',
              },
            ],
          },
          {
            'featureType': 'water',
            'elementType': 'geometry',
            'stylers': [
              {
                'color': '#b9b9b9',
              },
            ],
          },
          {
            'featureType': 'water',
            'elementType': 'labels.text.fill',
            'stylers': [
              {
                'color': '#999999',
              },
            ],
          },
        ],
        mapTypeControl: false,
      });
      this.initMarkers();
      const markerUrlInactiveObject = {
        url: this.markerUrlInactive,
        scaledSize: new google.maps.Size(50, 50),
        anchor: new google.maps.Point(25, 25),
      };
      const markerCluster = new MarkerClusterer({
        markers: this.markerArray, map: this.map,
        renderer: {
          render: ({count, position}) => {
            return new google.maps.Marker({
              label: {text: String(count), color: 'black', fontSize: '13px', fontWeight: 'bold'},
              position: {
                lat: position.lat(),
                lng: position.lng(),
              },
              map: this.map,
              icon: markerUrlInactiveObject,
            });
          },
        },
      });
      google.maps.event.addListener(markerCluster, 'click', (cluster) => {
          for (let j = 0; j < this.markerArray.length; j++) {
            this.markerArray[j].setIcon(markerUrlInactiveObject);
            this.infoboxArray[j].close();
          }
        },
      )
      ;
    }
  }

  initMarkers(): void {
    if (this.mapElem) {
      const markerData = JSON.parse(this.mapElem.dataset.locations || '');
      this.markerUrlInactive = this.mapElem.dataset.markerurlinactive || '';
      this.markerUrlActive = this.mapElem.dataset.markerurlactive || '';
      const markerUrlInactiveObject = {
        url: this.markerUrlInactive,
        scaledSize: new google.maps.Size(50, 50),
        anchor: new google.maps.Point(25, 25),
      };
      const markerUrlActiveObject = {
        url: this.markerUrlActive,
        scaledSize: new google.maps.Size(50, 50),
        anchor: new google.maps.Point(25, 25),
      };

      const bounds = new google.maps.LatLngBounds();

      for (let i = 0; i < markerData.length; i++) {
        this.markerArray[i] = new google.maps.Marker({
          position: {
            lat: markerData[i].lat,
            lng: markerData[i].long,
          },
          map: this.map,
          icon: markerUrlInactiveObject,
        });

        const pos = this.markerArray[i].getPosition();
        if (pos instanceof google.maps.LatLng) {
          bounds.extend(pos);
        }

        this.infoboxArray[i] = new google.maps.InfoWindow({
          content: markerData[i].text,
        });

        google.maps.event.addListener(this.infoboxArray[i], 'closeclick', () => {
          this.markerArray[i].setIcon(markerUrlInactiveObject);
        });

        this.markerArray[i].addListener('click', () => {
          for (let j = 0; j < this.markerArray.length; j++) {
            this.markerArray[j].setIcon(markerUrlInactiveObject);
            this.infoboxArray[j].close();
          }
          if (this.infoboxArray[i].getContent()) {
            this.infoboxArray[i].open(this.map, this.markerArray[i]);
            this.markerArray[i].setIcon(markerUrlActiveObject);
          }
        });
      }

      if (this.map) {
        this.map.fitBounds(bounds);
        this.map.setCenter(bounds.getCenter());
      }
    }
  }
}
