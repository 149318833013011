import {domReady} from './Functions/DomReady';
import TabNavigationController from './Controllers/TabNavigationController';
import AccordionController from './Controllers/AccordionController';
import ContactInfoController from './Controllers/ContactInfoController';
import MailtoDecryptController from './Controllers/MailtoDecryptController';
import MapController from './Controllers/MapController';
import {initHistorySlider} from './Functions/HistorySlider';
import '@pluswerk/thgeyer-shared-frontend/src/js/header-adjustments';
import PluginManager from '@pluswerk/thgeyer-shared-frontend/lib/shopware/js/plugin-system/plugin.manager';
import FlyoutMenuPlugin from '@pluswerk/thgeyer-shared-frontend/lib/shopware/js/plugin/main-menu/flyout-menu.plugin.js';
import BaseSliderPlugin from '@pluswerk/thgeyer-shared-frontend/lib/shopware/js/plugin/slider/base-slider.plugin';
import ViewportDetection from '@pluswerk/thgeyer-shared-frontend/lib/shopware/js/helper/viewport-detection.helper';
import NativeEventEmitter from '@pluswerk/thgeyer-shared-frontend/lib/shopware/js/helper/emitter.helper';
import ScrollUpPlugin from '@pluswerk/thgeyer-shared-frontend/lib/shopware/js/plugin/scroll-up/scroll-up.plugin.js';
import OffcanvasMenuPlugin from '@pluswerk/thgeyer-shared-frontend/lib/shopware/js/plugin/main-menu/offcanvas-menu.plugin.js';
import {ListController} from './Controllers/ListController';
import ShopController from './Controllers/ShopController';
import VideoController from './Controllers/VideoController';
import CountUpController from './Controllers/CountUpController';
import MobileNavigationController from './Controllers/MobileNavigationController';
import SlimSelect from 'slim-select';
import {JobListController} from './Controllers/JobListController';
import {SalesContactController} from './Controllers/SalesContactController';
import {FileUploadController} from './Controllers/FileUploadController';
import {initAusPageAjax} from './Functions/AusPageAjax';
import {initLightbox} from './Functions/Lightbox';
import FormController from './Controllers/FormController';

domReady(() => {
  // @ts-ignore
  window.breakpoints = {'xs': 0, 'sm': 576, 'md': 768, 'lg': 992, 'xl': 1200, 'xxl': 1400};

  // @ts-ignore
  window.eventEmitter = new NativeEventEmitter();

  new MobileNavigationController();
  new TabNavigationController();
  new AccordionController();
  new ContactInfoController();
  new MailtoDecryptController();
  new MapController();
  new ViewportDetection();
  new ListController();
  new ShopController();
  new VideoController();
  new FormController();
  initHistorySlider();
  initLightbox();
  if (document.querySelectorAll('.js-countup')) {
    new CountUpController();
  }
  if (document.querySelectorAll('.js-job-filter')) {
    new JobListController();
  }
  if (document.querySelectorAll('.js-salescontact-filter')) {
    new SalesContactController();
  }
  if (document.querySelectorAll('.js-application-fileupload')) {
    new FileUploadController();
  }

  PluginManager.register('FlyoutMenu', FlyoutMenuPlugin, '[data-flyout-menu]');
  PluginManager.register('BaseSlider', BaseSliderPlugin, '[data-base-slider]');
  PluginManager.register('ScrollUp', ScrollUpPlugin, '[data-scroll-up]');
  // PluginManager.register('OffcanvasMenu', OffcanvasMenuPlugin, '[data-offcanvas-menu]');
  PluginManager.initializePlugins();
  initAusPageAjax();
});
