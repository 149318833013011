
export class ListController {
  private filterButtons;

  constructor() {
    this.filterButtons = document.querySelectorAll('.js-list-filter');

    this.filterButtons.forEach(button => {
      button.addEventListener('click', async (event) => {
        event.preventDefault();
        const listContainer = button.parentElement.parentElement.querySelector('.js-news-list-container') ?? button.parentElement.parentElement.querySelector('.js-events-list-container');
        const response = await fetch(button.href);
        const html = await response.text();
        let parser = new DOMParser();
        let parsedHtml = parser.parseFromString(html, 'text/html');
        if (listContainer?.classList.contains('js-news-list-container')) {
          listContainer.innerHTML = parsedHtml.querySelector('.js-news-list-container')?.innerHTML;
        } else {
          listContainer.innerHTML = parsedHtml.querySelector('.js-events-list-container')?.innerHTML;
        }
        button.parentNode.querySelectorAll('.js-list-filter').forEach(filterButton => {
          filterButton.classList.remove('filterButton--active');
        });
        button.classList.add('filterButton--active');
      });
    });
  }
}
