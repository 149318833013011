import SlimSelect from 'slim-select';
import {Option} from 'slim-select/dist/data';

export class JobListController {

  private filterSelects: NodeListOf<HTMLSelectElement> = document.querySelectorAll('.js-job-filter');
  private departmentSlimSelect: SlimSelect | null = null;
  private locationSlimSelect: SlimSelect | null = null;
  private levelSlimSelect: SlimSelect | null = null;
  private jobItems: NodeListOf<HTMLElement> = document.querySelectorAll('.js-job-item');
  private filter: Record<string, { selectedValue: string, activeValues: string[] }> = {};

  constructor() {
    this.filterSelects?.forEach(filterSelect => {
      this.initSlimSelect(filterSelect, filterSelect.id);
      this.filter[filterSelect.id] = {selectedValue: filterSelect.value, activeValues: []};
      filterSelect.addEventListener('change', () => {
        this.filter[filterSelect.id].selectedValue = filterSelect.value;
        this.updateJobList();
        this.setFilter();
      });
    });
    this.updateJobList();
    this.setFilter();
  }

  private updateJobList() {
    this.jobItems.forEach(jobItem => {
      jobItem.classList.add('hide');
    });
    jobItemLoop: for (let i = 0; i < this.jobItems.length; i++) {
      for (let filterKey in this.filter) {
        if (!this.filter.hasOwnProperty(filterKey)) {
          continue;
        }
        let filterValue = this.filter[filterKey].selectedValue;
        if (!(this.jobItems[i].dataset[filterKey] === filterValue) && !(filterValue === 'all')) {
          continue jobItemLoop;
        }
      }
      this.jobItems[i].classList.remove('hide');
    }
  }

  private setFilter() {
    for (let setFilterKey in this.filter) {
      if (!this.filter.hasOwnProperty(setFilterKey)) {
        continue;
      }
      this.filter[setFilterKey].activeValues = [];
      jobItemLoop: for (let i = 0; i < this.jobItems.length; i++) {
        for (let jobItemKey in this.filter) {
          if (jobItemKey === setFilterKey) {
            continue;
          }
          if (!(this.jobItems[i].dataset[jobItemKey] === this.filter[jobItemKey].selectedValue) && this.filter[jobItemKey].selectedValue !== 'all') {
            continue jobItemLoop;
          }
        }
        let itemFilterValue = this.jobItems[i].dataset[setFilterKey];
        if (itemFilterValue) {
          if (!this.filter[setFilterKey].activeValues.includes(itemFilterValue)) {
            this.filter[setFilterKey].activeValues.push(itemFilterValue);
          }
        }
      }
      this.setSelectByKey(setFilterKey);
    }
  }

  private setSelectByKey(filterKey: string) {
    const activeValues = this.filter[filterKey].activeValues;
    if (filterKey === 'department') {
      this.departmentSlimSelect?.data.data.forEach((option: Option) => {
        if (option.value) {
          option.disabled = !activeValues.includes(option.value) && !(option.value === 'all');
        }
      });
      this.departmentSlimSelect?.render();
    }
    if (filterKey === 'location') {
      this.locationSlimSelect?.data.data.forEach((option: Option) => {
        if (option.value) {
          option.disabled = !activeValues.includes(option.value) && !(option.value === 'all');
        }
      });
      this.locationSlimSelect?.render();
    }
    if (filterKey === 'level') {
      this.levelSlimSelect?.data.data.forEach((option: Option) => {
        if (option.value) {
          option.disabled = !activeValues.includes(option.value) && !(option.value === 'all');
        }
      });
      this.levelSlimSelect?.render();
    }
  }

  private initSlimSelect(htmlSelect: HTMLSelectElement, id: string) {
    if (id === 'department') {
      this.departmentSlimSelect = new SlimSelect({
        select: htmlSelect,
        showSearch: false,
      });
      return;
    }
    if (id === 'location') {
      this.locationSlimSelect = new SlimSelect({
        select: htmlSelect,
        showSearch: false,
      });
      return;
    }
    if (id === 'level') {
      this.levelSlimSelect = new SlimSelect({
        select: htmlSelect,
        showSearch: false,
      });
      const params = new URLSearchParams(window.location.search);
      if (params.has('level')) {
        this.levelSlimSelect.setSelected(params.get('level') as string);
      }
    }
  }
}
