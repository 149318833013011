export default class VideoController {

  constructor() {
    const allVideos: NodeListOf<HTMLVideoElement> = document.querySelectorAll('.js-video video');
    const allPlayButtons: NodeListOf<HTMLElement> = document.querySelectorAll('.js-video-playbutton');

    if (allVideos.length > 0) {
      for (let i = 0; i < allVideos.length; i++) {
        allVideos[i].controls = false;
        allPlayButtons[i].addEventListener('click', () => {
          allVideos[i].controls = true;
          allPlayButtons[i].classList.add('hide');
          allVideos[i].play();
        });
      }
    }
  }
}
