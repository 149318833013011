export default class CountUpController {
  constructor() {

    const duration = 2000;
    const plusCounter = document.querySelectorAll('.js-countup-plus');
    const infinityCounter: HTMLElement | null = document.querySelector('.js-countup-infinity');
    plusCounter.forEach((counter) => {
      if (counter instanceof HTMLElement) {
        let end;
        let counterResult = counter.dataset.result;

        end = counterResult?.replace('+', '');
        this.animateValue(counter, 0, end, duration);
        setTimeout(
          () => {
            counter.innerHTML = counterResult!;
          },
          duration + 50);
      }
    });


    if (infinityCounter) {
      let resultIcon = infinityCounter.innerHTML.substring(1);
      this.animateValue(infinityCounter, 0, 999, duration);
      setTimeout(
        () => {
          infinityCounter!.innerHTML = resultIcon!;
          document.querySelector('.js-infinity-icon')?.classList.remove('hide');
        },
        duration + 50);
    }
  }

  private animateValue(counter: HTMLElement, start: number, end: number, duration: number) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp!) / duration, 1);
      counter.innerHTML = Math.floor(progress * (end - start) + start).toString();
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }
}
